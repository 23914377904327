import React from "react";

// Components
import Card from "../Card";
import Grid, { GridProps } from "@material-ui/core/Grid";
import Image from "../image";

// Types
import { CSSProperties } from "@material-ui/styles";

const imageStyles: CSSProperties = {
  height: 64,
  maxWidth: 64,
  margin: "auto",
} as const;

const problems = [
  {
    imageFilename: "refresh.png",
    title: "Collecte - Mise à jour - Diffusion",
    description:
      "Automatisez simplement la récupération et la diffusion de vos données et documents avec tous vos contacts",
    imageStyles: {
      ...imageStyles,
      width: 52,
    },
  },
  {
    imageFilename: "personal-information.png",
    title: "Données personnelles",
    description:
      "Centralisez la gestion de vos données personnelles et de tous vos documents d'identité, familiaux, professionnels, administratifs, juridiques, …",
    imageStyles,
  },
  {
    imageFilename: "control-icon.png",
    title: "Simplicité",
    description:
      "eKee automatise tous vos besoins de collecte et de diffusion de données et de documents",
    imageStyles,
  },
  {
    imageFilename: "shield-lock.png",
    title: "e-PRIVACY",
    description:
      "Vos données vous appartiennent. Maîtrisez en permanence les consentements que vous accordez pour partager vos données et documents.",
    imageStyles: {
      ...imageStyles,
      width: 54,
    },
  },
  {
    imageFilename: "efficiency-icon.png",
    title: "Gain de temps",
    description:
      "Fini la contrainte de fournir toujours les mêmes informations et les mêmes documents. Accordez un simple consentement pour donner accès aux seules informations qui vous sont demandées.",
    imageStyles,
  },
  {
    imageFilename: "zen.png",
    title: "Accessibilité",
    description:
      "Vos données et vos documents toujours disponibles. eKee vous libère d'une multitude de contraintes administratives.",
    imageStyles,
  },
] as const;

export type KeyPointsProps = GridProps;

const KeyPoints = (props: KeyPointsProps): JSX.Element => {
  return (
    <Grid container spacing={3} {...props}>
      {problems.map((p) => (
        <Grid item xs={12} sm={4} key={p.title}>
          <Card
            title={p.title}
            description={p.description}
            illustration={
              <Image style={p.imageStyles} filename={p.imageFilename} />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default KeyPoints;
