import React from "react";

// Components
import Panel, { IPanel } from "../Panel";

const panels: ReadonlyArray<IPanel> = [
  {
    id: 1,
    imageFilename: "documents-panel.png",
    title: "Gestion de documents",
    learn_more_link: "/blog/gestion-de-documents",
    body: (
      <>
        Pièces d'identités, RIB, factures, contrats, documents administratifs,
        assurances,&nbsp;…
        <br />
        eKee vous permet d'identifier efficacement vos documents pour les
        retrouver et les communiquer très facilement.
      </>
    ),
  },
  {
    id: 2,
    imageFilename: "contacts-panel.png",
    title: "Gestion de contacts",
    learn_more_link: "/blog/gestion-de-contacts",
    body: (
      <>
        Gérez tous vos contacts : famille, amis, commerçants, artisans,
        médecins, administration,&nbsp;...
        <br />
        Disposez de leurs coordonnées complètes et à jour en permanence avec les
        consentements eKee.
      </>
    ),
  },
  {
    id: 3,
    imageFilename: "ekee-consents.png",
    title: "Gestion des consentements",
    learn_more_link: "/blog/gestion-de-consentements",
    body: (
      <>
        Gérez comme vous le souhaitez les consentements que vous avez accordés
        et visualisez en permanence les consentements qui vous ont été accordés.
      </>
    ),
  },
];

export const Features = (): JSX.Element => (
  <>
    {panels.map((p, idx) => (
      <Panel key={p.id} panel={p} imageRight={idx % 2 === 0} />
    ))}
  </>
);

export default Features;
